import {FileAPI} from "../api/file";

/**
 * 파일 업로드
 * @param file
 */
export const uploadFile = async (file: File, isPublic: boolean = false, onProgress?: (progressEvent: any) => void, isMail?: boolean): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    const res = isMail ?  await FileAPI.uploadToNhn(formData, isPublic, onProgress) : await FileAPI.upload(formData, isPublic, onProgress);
    return res.data;
};
