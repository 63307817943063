import React from "react";
import { useTranslation } from "next-i18next";

interface Props {
  loading?: boolean;
  className?: string;
  message?: boolean;
  satisfaction?: string;
  total?: any;
}

export default function NoData(props: Props) {
  const { t } = useTranslation();
  const { message, loading = false, satisfaction, total } = props;
  const className =
    props.className || "w-full h-full min-h-300px flex items-center";

  return (
    <div className={className}>
      <div className="w-full text-center">
        {message ? (
          <>{loading ? t("내역을 불러오는 중 입니다") : t("내역이 없습니다")}</>
        ) : (
          <>
            {loading
              ? total?.answersPerSurveyIdTotalSize == 0 ? t("데이터가 없습니다") : t("데이터를 불러오는 중 입니다")
              : satisfaction ? satisfaction : t("데이터가 없습니다")}
          </>
        )}
      </div>
    </div>
  );
}
