import styles from "./SubscriptionHomeNewChannelContentCard.module.css";
import { useTranslation } from "next-i18next";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { ChannelContent } from "components/types/ChannelContent";
import Thumbnail from "components/common/Thumbnail";
import clsx from "clsx";
import {
  getClassificationPath,
  getContentPoolTypeCodeInfo,
  objCookiesDay,
  PreviousPageCode,
} from "shared/utils/CommonCode";
import { Grow, Tooltip, TooltipProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import KirdModal from "components/common/KirdModal";
import { TransitionProps } from "@material-ui/core/transitions";
import SubscriptionChannelPlayListPopup from "partials/subscription/playlist/SubscriptionChannelPlayListPopup";
import { useUserContext } from "shared/user-context";

// 채널 학습 시작
const BEGIN_CHANNEL_STUDY = gql`
  mutation BeginChannelStudy($request: BeginChannelStudyInput!) {
    study: beginChannelStudy(request: $request) {
      id
    }
  }
`;

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return (
    <Grow
      in={true}
      ref={ref}
      {...props}
      timeout={{ appear: 0, enter: 500, exit: 200 }}
    />
  );
});

// CSS
const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: 315,
    height: 255,
    "@media (max-width: 1280px)": {
      width: 155,
      height: 125,
    },
  },
}));

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

interface Props {
  channelContent: ChannelContent;
  setOpenLoginInfo?: Function;
  setGetCardInfo?: Function;
}

export default function SubscriptionHomeNewChannelContentCard(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { push, replace, query } = useRouter();
  const { user } = useUserContext();

  // 채널 콘텐츠
  const [channelContent, setChannelContent] = useState(props.channelContent);

  // 플레이리스트 추가 팝업 활성화 여부
  const [openAddPlayList, setOpenAddPlayList] = useState<boolean>(false);

  // 모달 팝업 닫기
  const closeModal = useCallback((setter: Function) => setter(false), []);

  // 채널 학습 시작
  const [fnBeginChannelStudy] = useMutation(BEGIN_CHANNEL_STUDY, {
    onCompleted: (data) => {
      if (data?.study?.id) {
        // 학습창으로 이동
        fnGoStudy(data?.study?.id);
      } else {
        enqueueSnackbar(`데이터 불러오기에 실패하였습니다.`, {
          variant: "error",
        });
        setClicked(false);
      }
    },
    onError: () => {
      enqueueSnackbar(`데이터 불러오기에 실패하였습니다.`, {
        variant: "error",
      });
      setClicked(false);
    },
  });

  // 이중 선택 방지
  const [clicked, setClicked] = useState(false);
  const checkCookie = objCookiesDay.get({ name: "loginPopup" })
  // 학습 체크
  const fnCheckStudy = (channelStudyId?: string | null) => {
    // if (clicked) return;
    // setClicked(true);

    if (!user?.authenticated) {
      if (checkCookie) {
        replace({
          pathname: `/subscription/channel/[cid]/content`,
          query: {
              ...query,
              cid: String(channelContent.channel?.id),
              contentId: channelContent.id,
          }
        });
      } else {
        props.setOpenLoginInfo(true)
        props.setGetCardInfo(channelContent)
      }
      return;
    }

    if (channelStudyId) {
      // 학습창으로 이동
      fnGoStudy(channelStudyId);
    } else {
      let request = {
        contentId: channelContent.id,
      };

      // 채널 학습 시작
      fnBeginChannelStudy({ variables: { request } });
    }
  };

  // 학습창으로 이동
  const fnGoStudy = (channelStudyId?: string | null) => {
    if (!!channelContent.channel) {
      replace({
        pathname: `/subscription/channel/[cid]/content/[sid]`,
        query: {
          ...query,
          cid: String(channelContent.channel.id),
          sid: String(channelStudyId),
          contentId: channelContent.id,
          referer: PreviousPageCode.SUBSCRIPTION_HOME,
        },
      });
    } else {
      enqueueSnackbar(`데이터 불러오기에 실패하였습니다.`, {
        variant: "error",
      });
      setClicked(false);
    }
  };

  // 내 플레이리스트 여부 설정
  const fnSetMyPlayList = (myPlayList: boolean) => {
    if (channelContent.myPlayList !== myPlayList) {
      setChannelContent({
        ...channelContent,
        myPlayList: myPlayList,
      });
    }
  };

  useEffect(() => {
    setClicked(false);

    return () => setClicked(false);
  }, []);

  return (
    <div className={styles.cptThumBoard} onClick={()=> !user?.authenticated && !checkCookie && props.setOpenLoginInfo(true)}>
      <div className={styles.thumImgGroup}>
        <div className={styles.imgBox}>
          <Thumbnail
            id={channelContent?.thumbnail?.id}
            alt={channelContent?.name}
            className={classes.thumbnail}
            onClick={() => fnCheckStudy()}
          />
        </div>
        <div className={styles.imgIcon}>
          {channelContent.type === 2 && (
            <img
              src="/images/icon/ico-36-category-video.svg"
              alt="콘텐츠 유형 - 동영상"
            />
          )}
          {channelContent.type === 3 && (
            <img
              src="/images/icon/ico-36-category-audio.svg"
              alt="콘텐츠 유형 - 오디오"
            />
          )}
          {channelContent.type === 4 && (
            <img
              src="/images/icon/ico-36-category-card.svg"
              alt="콘텐츠 유형 - 카드"
            />
          )}
          {channelContent.type === 5 && (
            <img
              src="/images/icon/ico-36-category-video.svg"
              alt="콘텐츠 유형 - 웹영상"
            />
          )}
          {channelContent.type === 6 && (
            <img
              src="/images/icon/ico-36-category-link.svg"
              alt="콘텐츠 유형 - 링크"
            />
          )}
          {channelContent.type === 7 && (
            <img
              src="/images/icon/ico-36-category-card.svg"
              alt="콘텐츠 유형 - PDF"
            />
          )}
          {channelContent.type === 8 && (
            <img
              src="/images/icon/ico-36-category-card.svg"
              alt="콘텐츠 유형 - 텍스트"
            />
          )}
        </div>
        <div className={styles.imgInfo}>
          <ul className={styles.infoBox}>
            {/* 플레이리스트 관련 주석처리 */}
             {user?.authenticated && (
              <li
                className={clsx(styles.infoPlay, {
                  [styles.on]: channelContent.myPlayList,
                })}
              >
                <BootstrapTooltip
                  title={`${t("플레이리스트 관리")}`}
                  placement="top-end"
                >
                  <button
                    className={styles.icon}
                    onClick={() => setOpenAddPlayList(true)}
                  >
                    {t("플레이리스트")}
                  </button>
                </BootstrapTooltip>
                <KirdModal
                  title={t("내 플레이리스트에 담기")}
                  fullWidth={true}
                  maxWidth="md"
                  transitionComponent={Transition}
                  open={openAddPlayList}
                  onClose={() => closeModal(setOpenAddPlayList)}
                >
                  <SubscriptionChannelPlayListPopup
                    channelContent={channelContent}
                    callback={fnSetMyPlayList}
                    onClose={() => closeModal(setOpenAddPlayList)}
                  />
                </KirdModal>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.thumContGroup}>
        <div className={styles.thumInfo}>
          <span className={clsx(styles.infoBlack)}>
            {channelContent.type
              ? t(getContentPoolTypeCodeInfo[channelContent.type]?.name) || "-"
              : "-"}
          </span>
        </div>
        <div className={styles.thumTitle}>
          <button className={styles.thumLink} onClick={() => fnCheckStudy()}>
            <h5>{channelContent.name}</h5>
          </button>
        </div>
        <div className={styles.thumGroup}>
          {channelContent?.classifications &&
          channelContent?.classifications?.length > 0
            ? getClassificationPath(channelContent?.classifications[0])
            : "-"}
        </div>
        <div className={styles.thumEtc}>
          <span className={styles.free}>
            {channelContent?.channel?.name || "-"}
            {channelContent?.channel?.influencer?.name &&
              ` | ${channelContent?.channel?.influencer?.name}`}
          </span>
          <span className={styles.hide}>{t("좋아요")}:</span>
          <span
            className={channelContent.myFavorite ? styles.liked : styles.like}
          >
            {channelContent.favorites?.toLocaleString() || 0}
          </span>
          <span className={styles.hide}>{t("조회수")}:</span>
          <span className={styles.view}>
           {(channelContent.views + channelContent.nonLoginViews)?.toLocaleString() || 0}
          </span>
        </div>
      </div>
    </div>
  );
}
