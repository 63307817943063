import { decode } from "html-entities";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { BASE_DOMAIN } from "./CommonCode";
import axios from "axios";

/**
 * 디바이스 체크
 */
export const checkDevice = () => {
  let ios = false;
  let android = false;

  if (navigator) {
    const agent = navigator.userAgent.toUpperCase();
    if (agent.indexOf("IPHONE") > -1 || agent.indexOf("IPAD") > -1) ios = true;
    if (agent.indexOf("ANDROID") > -1) android = true;
  }

  return {
    ios: ios,
    android: android,
  };
};

/**
 * 모바일 여부
 */
export const isMobileBrowser = () => {
  const deviceInfo = checkDevice();

  if (deviceInfo.ios || deviceInfo.android || window.innerWidth < 1023)
    return true;

  return false;
};

/**
 * 함수는 정규식을 사용하여 문자열에서 모든 HTML 태그를 제거
 */
export function stripHtmlTags(str) {
  // HTML 태그 제거
  const noTags = str.replace(/<\/?[^>]+(>|$)/g, "");

  // HTML 엔티티 디코딩
  const decodedStr = decode(noTags);

  return decodedStr;
}

/**
 * 모바일 앱 여부
 */
export const isMobileApp = () => {
  if (navigator) {
    const agent = navigator.userAgent.toUpperCase();
    if (agent.indexOf("FIRBMOBILE") > -1) return true;
  }

  return false;
};

/**
 * 쿠키 설정
 */
export const objCookies = {
  get: ({ name }: { name: string }) => {
    const cookies = parseCookies();

    if (cookies[name] !== undefined) {
      const parseCookie = JSON.parse(cookies[name]);
      return typeof parseCookie === "string" ? [parseCookie] : parseCookie;
    } else {
      return undefined;
    }
  },
  add: ({ path, name, value }: { path?: string; name: string; value: any }) => {
    const cookies = parseCookies();
    if (path === undefined) path = "/";

    let newValues = new Array<string>();
    if (cookies[name] !== undefined) {
      const parseCookie = JSON.parse(cookies[name]);
      newValues = typeof parseCookie === "string" ? [parseCookie] : parseCookie;
    }

    newValues.push(value);
    setCookie(null, name, JSON.stringify(newValues), {
      maxAge: 30 * 24 * 60 * 60,
      path: path,

      secure: true,
    });
  },
  set: ({
    path,
    name,
    value,
    maxAge = 30 * 24 * 60 * 60,
  }: {
    path?: string;
    name: string;
    value: any;
    maxAge?: number | "session";
  }) => {
    if (path === undefined) path = "/";

    const _maxAge = maxAge === "session" ? undefined : maxAge;

    setCookie(null, name, value, {
      maxAge: _maxAge,
      path: path,
      secure: true,
    });
  },
  remove: ({
    path,
    name,
    value,
  }: {
    path?: string;
    name: string;
    value?: string;
  }) => {
    const cookies = parseCookies();
    if (path === undefined) path = "/";

    if (value === undefined) {
      destroyCookie(null, name, {
        path: path,
      });
    } else {
      let newValues = new Array<string>();
      if (cookies[name] !== undefined) {
        const parseCookie = JSON.parse(cookies[name]);
        newValues =
          typeof parseCookie === "string" ? [parseCookie] : parseCookie;
      }

      if (value !== undefined) {
        newValues = newValues?.filter((newValue) => newValue !== value);
      }
      setCookie(null, name, JSON.stringify(newValues), {
        maxAge: 30 * 24 * 60 * 60,
        path: path,
      });
    }
  },
};

export const datePickerOpenEvent = () => {
  setTimeout(() => {
    document
      .querySelectorAll(".MuiPickersCalendarHeader-iconButton")
      .forEach((node: HTMLButtonElement, inx) => {
        if (inx === 0) {
          node.title = "이전 달";
        } else {
          node.title = "다음 달";
        }
      });
    document
      .querySelectorAll(
        ".MuiTypography-subtitle1,.MuiPickersCalendarHeader-dayLabel"
      )
      .forEach((node: HTMLElement) => {
        node.setAttribute("style", "color:#1c1c1c");
      });
    document
      .querySelectorAll(".MuiTabs-flexContainer .MuiButtonBase-root")
      .forEach((node: HTMLButtonElement, inx) => {
        if (inx === 0) {
          node.title = "날짜 선택";
        } else {
          node.title = "시간 선택";
        }
      });
  }, 100);
};

/**
 * alpha-campus.kr 도메인인지 확인
 */
export const fnCheckBaseDomain = () => {
  return window.location.href.startsWith(`https://${BASE_DOMAIN}`);
};

/**
 * NOTE kms axios 호출 부
 */
export async function postResponseData(qry, graphqlEndpoint) {
  return axios
    .post(graphqlEndpoint, { query: qry })
    .then((response) => {
      let data = response.data.data;
      return data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      throw error;
    });
}