import React, { useEffect, useState } from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import {
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFileWord,
} from "react-icons/fa";
import {
  AiFillAudio,
  AiOutlineFilePdf,
  AiOutlinePaperClip,
} from "react-icons/ai";
import { MdOndemandVideo } from "react-icons/md";
import { StoredFile } from "components/types/StoredFile";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { uploadFile } from "shared/utils/FileUtil";

const twclasses = {
  base: "flex justify-center items-center p-1 border-2 rounded-md border-gray-300 border-dashed bg-none text-[#7F7F7F] transition duration-300 min-h-[4rem]",
  active: "border-green-500",
  accept: "border-blue-500 bg-blue-100",
  reject: "border-red-500 bg-red-100",
};

// CSS
const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      height: 6,
      "& > *": {
        height: 6,
      },
    },
    sizeText: {
      fontSize: 12,
      color: "#9ca3af",
    },
    nowIsLoading: {
      position: "absolute",
      top: "50%",
      left: "50%",
      color: "white",
      background: "rgba(0,0,0,0.5)",
      width: "100%",
      height: "100%",
      transform: "translate(-50%, -50%)",
      zIndex: 999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pointerEvents: "none",
    },
  })
);

interface LinearProgressWithLabelProps extends LinearProgressProps {
  uploadInfo: any;
}

function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
  const { uploadInfo } = props;
  const value = Math.floor(
    ((uploadInfo.completeCount + uploadInfo.errorCount) /
      uploadInfo.totalCount) *
      100
  );

  return (
    <Box className="flex items-center">
      <Box mr={1} className="flex-auto">
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${value}% (${
          uploadInfo.completeCount + uploadInfo.errorCount
        } of ${uploadInfo.totalCount})`}</Typography>
      </Box>
    </Box>
  );
}

export type DZFile =
  | DropzoneImageFile
  | File
  | DropzoneImageAttachment
  | Attachment;

export interface DropzoneImageFile extends File {
  preview: string;
}

export interface Attachment {
  name: string;
  size: number;
  type: string;
}

export interface DropzoneImageAttachment extends Attachment {
  preview: string;
}

export interface DeleteFileHandler {
  file?: File;
  fileId?: string;
  allFiles?: File[];
  allFileIds?: string[];
}

export interface DropzoneComponentOptions extends DropzoneOptions {
  children?: any;
  multiple?: boolean;
  directory?: boolean;
  customStyle?: string;
  placeholder?: string;
  uploadInfo?: any;
  onChange?: (files: File[], allFiles?: File[]) => void;
  onUpload?: (fileIds: string[], allFileIds?: string[]) => void;
  onDelete?: ({
    file,
    fileId,
    allFiles,
    allFileIds,
  }: DeleteFileHandler) => void;
  attachments?: StoredFile[];
  readOnly?: boolean;
  displayCount?: boolean;
  useUpload?: boolean;
  isPublic?: boolean;
  isOnlineContent?: boolean;
  acceptFileSize?: number;
  uploadCompleted?: boolean;
  setUploadCompleted?: Function;
}

export enum AcceptType {
  IMAGE = "image/jpeg, image/png, image/gif",
  VIDEO = "video/mp4",
  AUDIO = "audio/mpeg, audio/mp4",
  ZIP = "application/zip, application/x-zip-compressed",
  UNKNOWN = "image/jpeg, image/png, image/gif, video/mp4, audio/mpeg, application/zip, application/x-zip-compressed, application/pdf, application/haansofthwp, application/x-hwp, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/html, .hwpx ,.ipynb",
  PDF = "application/pdf",
  DOCUMENT = "application/haansofthwp, application/x-hwp, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .hwpx ,.ipynb",
  EXCEL = "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PAPER = "image/jpeg, image/png, image/gif, application/pdf", // 회원가입 사업자 등록증
  NULL = ""
}

export default function DropzoneComponent({
  accept = AcceptType.UNKNOWN,
  maxFiles = 1,
  children,
  multiple,
  directory,
  customStyle,
  placeholder,
  uploadInfo,
  onChange,
  onUpload,
  onDelete,
  attachments,
  uploadCompleted,
  setUploadCompleted,
  readOnly,
  displayCount = true,
  useUpload = true,
  isPublic = false,
  acceptFileSize = 1000000000,  // NOTE: lsh 용량 설정이 없을경우 기본 1GB로 설정
  ...restOptions
}: DropzoneComponentOptions) {
  const classes = useStyles();
  const sizeText = useStyles();
  const nowIsLoading = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<DZFile[]>([]);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const fileInput = React.useRef<HTMLInputElement>(null);
  const [applied, setApplied] = useState(false);
  const [isNowUpLoading, setIsNowUpLoading] = useState<boolean>(false);

  // edit mode
  useEffect(() => {
    if (applied) return;
    const newAttachments = attachments?.filter((a) => a != null);

    if (newAttachments?.length) {
      // fileIds
      const _ids = newAttachments.map((a) => a.id);
      setFileIds(_ids);

      // files
      const _files = newAttachments.map(({ id, name, contentType, size }) => {
        const _file = {
          name,
          size,
          type: contentType,
        };
        if (AcceptType.IMAGE.indexOf(contentType) > -1) {
          // TODO accessToken 변경할 것!
          (
            _file as DropzoneImageAttachment
          ).preview = `/api/file/${id}?accessToken=ok`;
        }
        return _file;
      });
      setFiles(_files);
      setApplied(true);
    }
  }, [attachments, applied]);

  const onDrop = (acceptedFiles: any[], fileRejections: FileRejection[]) => {
    // max count
    if (
      !multiple &&
      maxFiles !== 0 &&
      files.length + acceptedFiles.length > maxFiles
    ) {
      enqueueSnackbar(`파일 첨부는 ${maxFiles}개 까지만 가능합니다`, {
        variant: "error",
      });
      return;
    }

    const _files = [
      ...files,
      ...(accept === AcceptType.IMAGE
        ? acceptedFiles.map((file: File) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          )
        : acceptedFiles),
    ];


    if (fileRejections.length > 0) {
      enqueueSnackbar(
        `허용하는 파일형식이 아닙니다.`,
        { variant: "error" }
      );
      return;
    }

    if (_files[_files.length - 1]?.size > acceptFileSize) {
      if(acceptFileSize < 1000000000) {
        const printSize = acceptFileSize === 2000000 ? "2MB" : "5MB"
        enqueueSnackbar(
            `파일의 최대 용량은 ${printSize} 까지 입니다. (용량이 초과된 파일은 첨부되지 않습니다)`,
            {variant: "error"}
        );
      } else {
        enqueueSnackbar(
            `파일의 최대 용량은 ${acceptFileSize / 1000000000}GB 까지 입니다. (용량이 초과된 파일은 첨부되지 않습니다)`,
            {variant: "error"}
        );
      }
      return;
    }

    setFiles(_files);
    setIsNowUpLoading(true);
    !!setUploadCompleted && setUploadCompleted(false);
    if (acceptedFiles.length > 0) {
      // callback
      !!onChange && onChange(acceptedFiles, _files as File[]);

      if (useUpload) {
        Promise.allSettled(
          acceptedFiles.map((file) => uploadFile(file, isPublic, (event) => {
            if (event.lengthComputable) {
              const percentage = Math.round((event.loaded / event.total) * 100);

              // 해당 파일의 진행률 업데이트
              const fileIndex = _files.findIndex((f) => f === file);
              if (fileIndex !== -1) {
                _files[fileIndex].progress = percentage;
                setFiles([..._files]); // _files 업데이트
              }
            }
          }))
        ).then((results) => {
          const successValues = results
            .filter((r) => r.status === "fulfilled")
            .map((r) => (r as PromiseFulfilledResult<string>).value);

          /**
           * 작업일 20241018
           * 작업코드  : KIRD2024-4
           * 요청자 : 이지영
           * TODO : KIRD2024-4
           */
          if(successValues.length > 0){
            const _fileIds = [...fileIds, ...successValues];
            setFileIds(_fileIds);

            // callback
            !!onUpload && onUpload(successValues, _fileIds);
            setIsNowUpLoading(false);
            !!setUploadCompleted && setUploadCompleted(true);
          } else {
            setFiles([...files]);
            setFileIds([...fileIds]);
            setIsNowUpLoading(false);
            enqueueSnackbar(
                `허용하는 파일형식이 아닙니다.`,
                { variant: "error" }
            );
            return;
          }
        });
      } else {
        setFiles([]);
        setFileIds([]);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    ...restOptions,
  });

  const removeFile = (e: any, file: File, idx: number) => {
    e.preventDefault();
    e.stopPropagation();

    // files
    const _files = [...files].filter((f) => f !== file);
    setFiles(_files);

    // fileIds
    const _fileIds = [...fileIds];
    const deletedId = _fileIds.splice(idx, 1)[0];
    setFileIds(_fileIds);

    // callback
    if (onDelete) {
      onDelete({
        file,
        fileId: deletedId,
        allFiles: _files as File[],
        allFileIds: _fileIds,
      });
    }
  };

  const typeIcon = (type: string) => {
    const baseClasses = "text-xl mr-2";
    if (!type) {
      return (
        <AiOutlinePaperClip className={clsx(baseClasses, "text-gray-600")} />
      );
    } else if (type.endsWith("document") || type.endsWith("msword")) {
      return <FaRegFileWord className={clsx(baseClasses, "text-[#295494]")} />;
    } else if (type.endsWith("sheet") || type.endsWith("excel")) {
      return <FaRegFileExcel className={clsx(baseClasses, "text-[#0e783e]")} />;
    } else if (type.endsWith("presentation") || type.endsWith("powerpoint")) {
      return (
        <FaRegFilePowerpoint className={clsx(baseClasses, "text-[#cb4525]")} />
      );
    } else if (type === "application/pdf") {
      return (
        <AiOutlineFilePdf className={clsx(baseClasses, "text-[#ad0b00]")} />
      );
    } else if (type === "video/mp4") {
      return (
        <MdOndemandVideo className={clsx(baseClasses, "text-indigo-600")} />
      );
    } else if (type === "audio/mpeg") {
      return <AiFillAudio className={clsx(baseClasses, "text-blue-600")} />;
    }
  };

  // preview for image type
  const thumbs = (
    <aside className="w-full flex flex-wrap justify-center space-x-2">
      {files.map((file, idx) => (
        <div
          key={idx}
          className="relative text-center max-w-[258px] max-h-[258px] group p-1 border bg-gray-100 group-hover:shadow-lg rounded"
        >
          {!readOnly && (
            <div
              className="hidden group-hover:block absolute z-10 top-4 right-2 bg-red-500 rounded-full h-8 w-8 flex justify-center items-center text-white font-bold cursor-pointer"
              onClick={(e) => removeFile(e, file as File, idx)}
            >
              <CloseIcon />
            </div>
          )}
          <img
            className="object-center max-w-[250px] w-full h-48 transition transform scale-95 group-hover:scale-100 rounded"
            src={(file as DropzoneImageFile).preview}
            alt={file.name}
          />
        </div>
      ))}
    </aside>
  );

  // attached list for files
  const list = (
    <aside className="w-full">
      {files.map((file: any, idx) => (
        <div key={idx} className="p-2 border-b bg-gray-50">
          <div className="flex justify-start items-center font-bold text-gray-700 p-2">
            {typeIcon(file.type)}
            <span className="text-sm">{file.name} {isNowUpLoading && `${file.progress} %`} {file.progress === 100 && "(업로드완료)"}</span>
            {!readOnly && (
              <button
                className="h-6 w-6 rounded-full bg-red-500 cursor-pointer text-xs text-white mx-4 flex justify-center items-center"
                onClick={(e) => removeFile(e, file as File, idx)}
                title="삭제하기"
              >
                <CloseIcon className="!h-5 !w-5" />
              </button>
            )}
          </div>
        </div>
      ))}
    </aside>
  );

  // clean up
  useEffect(
    () => () => {
      if (accept === AcceptType.IMAGE) {
        // 기존 첨부파일은 제외!
        files
          .filter((f) => !!(f as File).lastModified)
          .forEach((file) =>
            URL.revokeObjectURL((file as DropzoneImageFile).preview)
          );
      }
    },
    [files]
  );

  if (children !== undefined) {
    return (
      <div {...getRootProps()}>
        {!readOnly && (
          <React.Fragment>
            {directory ? (
              <React.Fragment>
                <input
                  type="file"
                  {...getInputProps()}
                  accept={accept as string}
                  ref={fileInput}
                  multiple={multiple}
                  /* @ts-expect-error */
                  directory=""
                  webkitdirectory=""
                />
                {!!uploadInfo && !uploadInfo.completed && (
                  <LinearProgressWithLabel uploadInfo={uploadInfo} />
                )}
                <div
                  className={clsx(
                    twclasses.base,
                    {
                      [twclasses.active]: isDragActive,
                      [twclasses.accept]: isDragAccept,
                      [twclasses.reject]: isDragReject,
                    },
                    customStyle
                  )}
                  onClick={() => fileInput.current!.click()}
                >
                  <div className="text-gray-500 text-center flex">
                    <div className="text-center py-2">
                      <div className="mr-2">
                        <CloudUploadOutlinedIcon />
                      </div>
                      <div className="text-sm">
                        {placeholder || "Drag your folder or click to upload."}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <input
                  type="file"
                  {...getInputProps()}
                  accept={accept as string}
                  ref={fileInput}
                  multiple={multiple}
                />
                {!!uploadInfo && !uploadInfo.completed && (
                  <div className={classes.progress}>
                    <LinearProgress />
                  </div>
                )}
                <div
                  className={clsx(
                    twclasses.base,
                    {
                      [twclasses.active]: isDragActive,
                      [twclasses.accept]: isDragAccept,
                      [twclasses.reject]: isDragReject,
                    },
                    customStyle
                  )}
                  onClick={() => fileInput.current!.click()}
                >
                  <div className="text-gray-500 text-center flex">
                    <div className="text-center py-2">
                      <div className="mr-2">
                        <CloudUploadOutlinedIcon />
                      </div>
                      <div className="text-sm">
                        {placeholder ||
                          "Drag your zip file or click to upload."}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {children}
      </div>
    );
  } else {
    return (
      <section className="p-2 border border-gray-300">
        {displayCount && maxFiles > 0 && (
          <div className="text-gray-500 text-center">
            <label htmlFor="file">첨부 가능</label>
            <span className="text-blue-500 font-semibold ">
              {" "}
              ({files.length} / {maxFiles})
            </span>
            {/* KIRD02-450 */}
            {acceptFileSize === 2000000 ? <span className={classes.sizeText}> {`(최대 2MB)`}</span> :
              acceptFileSize === 5000000 ? <span className={classes.sizeText}> {`(최대 5MB)`}</span> :
              <span className={classes.sizeText}> {`(최대 ${acceptFileSize / 1000000000}GB)`}</span> }
            </div>
            )}
            <div
                {...getRootProps()}
                className={clsx(
            twclasses.base,
            {
              [twclasses.active]: isDragActive,
              [twclasses.accept]: isDragAccept,
              [twclasses.reject]: isDragReject,
            },
            customStyle
          )}
        >
          {!readOnly && (
            <input
              id="file"
              type="file"
              {...getInputProps()}
              accept={accept as string}
            />
          )}
          {files.length > 0 ? (
            accept === AcceptType.IMAGE ? (
              thumbs
            ) : (
              list
            )
          ) : (
            <React.Fragment>
              <div className="text-center py-2">
                <div className="mr-2">
                  <CloudUploadOutlinedIcon />
                </div>
                <div className="text-sm">
                  {placeholder || "파일을 끌거나 클릭하여 업로드하십시오."}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {!setUploadCompleted && isNowUpLoading && (
          <div className={classes.nowIsLoading}>
            파일 업로드중입니다 잠시만 기다려주세요
          </div>
        )}
      </section>
    );
  }
}
