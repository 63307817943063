import {FormikProps} from "formik";
import {useSnackbar} from "notistack";
import {useEffect} from "react";

type ModeType = "ALL" | "NORMAL" | undefined;
export const FormikErrorSnackbar = (formik: FormikProps<any>, mode?: ModeType) => {
    const {enqueueSnackbar} = useSnackbar();
    if (formik) {
        useEffect(() => {
            if (formik.submitCount > 0 && formik.errors) {
                if (mode === "ALL") {
                    // let alarm = Object.values(formik.errors);
                    // if (alarm.length > 0) {
                    //     enqueueSnackbar(alarm[0], {
                    //         variant: 'error',
                    //     });
                    // }
                    let isAlert = false;
                    Object.keys(formik.errors)?.map(key => {
                        if (!isAlert) {
                            enqueueSnackbar(formik.errors[key], {variant: 'error'});
                            isAlert = true;
                        }
                    });
                } else {
                    let isAlert = false;
                    Object.keys(formik.errors)?.map(key => {
                        if (!isAlert && !formik.values[key]) {
                            enqueueSnackbar(formik.errors[key], {variant: 'error'});
                            isAlert = true;
                        }
                    });
                }
            }
        }, [formik.submitCount]);
    }
};
