import { StoredFile } from "components/types/StoredFile";
import { apis } from "../http/http-service";

////////////// Attach Files ///////////////////
const uploadFile = (formData: FormData, isPublic: boolean = false, onProgress?: (progressEvent: any) => void) =>
  apis.post(`/api/file/upload?public=${isPublic ? "true" : "false"}`, formData, {
    onUploadProgress: onProgress
  });

// NOTE: lsh 메일 발송 시 nhn서버로 보내기 위함
const uploadNhnFile = (formData: FormData, isPublic: boolean = false, onProgress?: (progressEvent: any) => void) =>
  apis.post(`/api/file/nhn/email/upload`, formData, {
    onUploadProgress: onProgress
  });

const DownloadZIPFile = async (fileId: string, name: string) => {
  const response = await fetch(`/api/onlinecontent/${fileId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('ZIP 파일 다운로드 실패');
  }

  const blob = await response.blob();
  const fileUrl = URL.createObjectURL(blob);

  // 이전 코드와 동일하게 링크 만들기 및 클릭 처리
  let link = document.createElement("a");
  link.style.cssText = "display:none";
  link.href = fileUrl;
  link.download = `${name}.zip`; // 파일 이름에 .zip 확장자 추가
  document.body.appendChild(link);
  await new Promise((resolve) => {
    link.addEventListener("click", (event) => {
      resolve("");
    });
    link.click();
  });
  link.remove();
};

const DownloadFile = async (fileId: string) => {
  let link = document.createElement("a");
  link.style.cssText = "display:none";
  // link.target = "_blank";
  link.href = `/api/file/${fileId}?accessToken=${"ok"}`; //TODO accessToken 로직 변경시 반영
  document.body.appendChild(link);
  await new Promise((resolve) => {
    link.addEventListener("click", (event) => {
      resolve("");
    });
    console.log("link : ", link);
    link.click();
  });
  link.remove();
};

const LearningLabNoticeDownload = async (fileIds: string[]) => {
  const downloadLinks = fileIds.map((fileId) => {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = `/api/file/${fileId}?accessToken=${"ok"}`; //TODO accessToken 로직 변경시 반영
    link.download = fileId;
    document.body.appendChild(link);
    return link;
  });

  const downloadPromises = downloadLinks.map((link) => {
    return new Promise((resolve) => {
      link.addEventListener("click", () => {
        link.remove();
        resolve("");
      });
      link.click();
    });
  });

  await Promise.all(downloadPromises);
};

const ImageDownloadFile = async (file: StoredFile) => {
  let link = document.createElement("a");
  link.style.cssText = "display:none";
  link.download = file.name;
  link.href = `/api/file/${file.id}?accessToken=${"ok"}`; //TODO accessToken 로직 변경시 반영
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const FileAPI = {
  upload: uploadFile,
  uploadToNhn: uploadNhnFile,
  download: DownloadFile,
  zipDownload: DownloadZIPFile,
  imageDownload: ImageDownloadFile,
  learningLabNoticeDownload: LearningLabNoticeDownload
};
