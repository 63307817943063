import React, {useEffect, useState} from 'react';
import styles from './SubscriptionChannelPlayListPopup.module.css';
import {useTranslation} from 'next-i18next';
import clsx from "clsx";
import {useFormik} from "formik";
import * as yup from "yup";
import {FormikErrorSnackbar} from "shared/utils/FormikErrorSnackbar";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import NoData from "components/common/NoData";
import {ChannelPlayList, CreateOrEditChannelPlaylistFormValues, CreateOrEditChannelPlaylistRequest} from "components/types/ChannelPlayList";
import {useSnackbar} from "notistack";
import {ChannelContent} from "components/types/ChannelContent";
import Box from "@material-ui/core/Box";
import DropzoneComponent, {AcceptType, DropzoneComponentOptions} from "components/common/DropzoneComponent";
import {StoredFile} from "components/types/StoredFile";
import {useUserContext} from "shared/user-context";
import {useConfirmation} from "components/common/confirmation/ConfirmationService";
import Grid from "@material-ui/core/Grid";

// 내 채널 플레이리스트 목록 조회
const GET_MY_CHANNEL_PLAY_LIST_LIST = gql`
    query GetMyChannelPlayListList($request: GetMyChannelPlayListListInput!) {
        channelPlayLists: getMyChannelPlayListList(request: $request) {
            id
            name
            openToThePublic
            thumbnail {
                id
                name: originFileName
                size
                contentType
            }
            owner {
                id
                name
            }
            channelContents {
                id
                myPlayList
            }
        }
    }
`;

// 채널 플레이리스트 등록
const CREATE_CHANNEL_PLAY_LIST = gql`
    mutation CreateChannelPlayList($request: CreateChannelPlayListInput!) {
        channelPlayList: createChannelPlayList(request: $request) {
            id
        }
    }
`;

// 채널 플레이리스트 수정
const EDIT_CHANNEL_PLAY_LIST = gql`
    mutation EditChannelPlayList($request: EditChannelPlayListInput!) {
        channelPlayList: editChannelPlayList(request: $request) {
            id
        }
    }
`;

// 채널 플레이리스트 삭제
const DELETE_CHANNEL_PLAY_LIST = gql`
    mutation DeleteChannelPlayList($request: DeleteChannelPlayListInput!) {
        channelPlayList: deleteChannelPlayList(request: $request) {
            id
        }
    }
`;

// 채널 플레이리스트 콘텐츠 추가
const ADD_CHANNEL_PLAY_LIST_CONTENT = gql`
    mutation AddChannelPlayListContent($request: AddChannelPlayListContentInput!) {
        channelPlayList: addChannelPlayListContent(request: $request) {
            id
        }
    }
`;

// 채널 플레이리스트 콘텐츠 삭제
const DELETE_CHANNEL_PLAY_LIST_CONTENT = gql`
    mutation DeleteChannelPlayListContent($request: DeleteChannelPlayListContentInput!) {
        channelPlayList: deleteChannelPlayListContent(request: $request) {
            id
        }
    }
`;

interface Props {
    channelContent: ChannelContent;
    callback: Function;
    onClose: Function;
};
export default function SubscriptionChannelPlayListPopup(props: Props) {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const {channelContent, callback, onClose} = props;
    const {user} = useUserContext();
    const confirm = useConfirmation();

    // 채널 콘텐츠 ID
    const channelContentId = channelContent.id;

    // 채널 플레이리스트 썸네일
    const [thumbnail, setThumbnail] = useState<StoredFile[]>([]);

    // 내 채널 플레이리스트 목록 조회
    const [fnGetMyChannelPlayListList, {
        loading: channelPlayListsLoading,
        error: channelPlayListsError,
        data: channelPlayListsData,
    }] = useLazyQuery(GET_MY_CHANNEL_PLAY_LIST_LIST, {fetchPolicy: "no-cache"});

    // 내 채널 플레이리스트 목록
    const channelPlayLists = channelPlayListsData?.channelPlayLists;

    useEffect(() => {
        !!callback && callback(channelPlayLists?.filter((channelPlayList: ChannelPlayList) => channelPlayList.channelContents?.some((channelContent: ChannelContent) => channelContent.id === channelContentId && channelContent.myPlayList))?.length > 0);
    }, [channelPlayLists]);

    // 채널 플레이리스트 등록
    const [fnCreateChannelPlayList] = useMutation(CREATE_CHANNEL_PLAY_LIST, {
        onCompleted: (data) => {
            const newId = data?.channelPlayList?.id;

            if (newId) {
                enqueueSnackbar(`플레이리스트 등록에 성공하였습니다.`, {
                    variant: 'success',
                });

                createFormik.resetForm();
                setThumbnail([]);
                setSelectedChannelPlayList(null);

                // 채널 플레이리스트 목록 조회
                fnGetMyChannelPlayListList({variables: {request: {}}});
            }
        },
        onError: () => {
            enqueueSnackbar(`플레이리스트 등록에 실패하였습니다.`, {
                variant: 'error',
            });
        }
    });

    // 채널 플레이리스트 수정
    const [fnEditChannelPlayList] = useMutation(EDIT_CHANNEL_PLAY_LIST, {
        onCompleted: (data) => {
            const newId = data?.channelPlayList?.id;

            if (newId) {
                enqueueSnackbar(`플레이리스트 수정에 성공하였습니다.`, {
                    variant: 'success',
                });

                createFormik.resetForm();
                setThumbnail([]);
                setSelectedChannelPlayList(null);

                // 채널 플레이리스트 목록 조회
                fnGetMyChannelPlayListList({variables: {request: {}}});
            }
        },
        onError: () => {
            enqueueSnackbar(`플레이리스트 수정에 실패하였습니다.`, {
                variant: 'error',
            });
        }
    });

    // 채널 플레이리스트 삭제
    const [fnDeleteChannelPlayList] = useMutation(DELETE_CHANNEL_PLAY_LIST, {
        onCompleted: (data) => {
            const newId = data?.channelPlayList?.id;

            if (newId) {
                enqueueSnackbar(`플레이리스트 삭제에 성공하였습니다.`, {
                    variant: 'success',
                });

                // 채널 플레이리스트 목록 조회
                fnGetMyChannelPlayListList({variables: {request: {}}});
            }
        },
        onError: () => {
            enqueueSnackbar(`플레이리스트 삭제에 실패하였습니다.`, {
                variant: 'error',
            });
        }
    });

    // 채널 플레이리스트 콘텐츠 추가
    const [fnAddChannelPlayListContent] = useMutation(ADD_CHANNEL_PLAY_LIST_CONTENT, {
        onCompleted: (data) => {
            const newId = data?.channelPlayList?.id;

            if (newId) {
                enqueueSnackbar(`플레이리스트 콘텐츠 선택에 성공하였습니다.`, {
                    variant: 'success',
                });

                // 채널 플레이리스트 목록 조회
                fnGetMyChannelPlayListList({variables: {request: {}}});
            }
        },
        onError: () => {
            enqueueSnackbar(`플레이리스트 콘텐츠 선택에 실패하였습니다.`, {
                variant: 'error',
            });
        }
    });

    // 채널 플레이리스트 콘텐츠 삭제
    const [fnDeleteChannelPlayListContent] = useMutation(DELETE_CHANNEL_PLAY_LIST_CONTENT, {
        onCompleted: (data) => {
            const newId = data?.channelPlayList?.id;

            if (newId) {
                enqueueSnackbar(`플레이리스트 콘텐츠 해제에 성공하였습니다.`, {
                    variant: 'success',
                });

                // 채널 플레이리스트 목록 조회
                fnGetMyChannelPlayListList({variables: {request: {}}});
            }
        },
        onError: () => {
            enqueueSnackbar(`플레이리스트 콘텐츠 해제에 실패하였습니다.`, {
                variant: 'error',
            });
        }
    });

    // 등록 유효성 체크
    const validationSchema = yup.object({
        // 공개여부
        openToThePublic: yup.string().required('공개여부를 선택해 주세요'),
        // 플레이리스트명
        name: yup.string().required('플레이리스트명을 입력해 주세요')
    });

    // 등록 Formik
    const createFormik = useFormik<CreateOrEditChannelPlaylistFormValues>({
        enableReinitialize: true,
        initialValues: {
            name: '',
            openToThePublic: true,
            thumbnailFileId: '',
        },
        validateOnChange: true,
        validationSchema,
        onSubmit: (values) => {
            let isNew = selectedChannelPlayList === null;

            let request: CreateOrEditChannelPlaylistRequest = {
                name: values.name,
                openToThePublic: values.openToThePublic,
                thumbnailFileId: values.thumbnailFileId,
            };

            if (!isNew) {
                request = {
                    ...request,
                    id: selectedChannelPlayList.id,
                };
            }
            
            if (isNew) {
                // 채널 플레이리스트 등록
                fnCreateChannelPlayList({variables: {request: {...request}}});
            } else {
                // 채널 플레이리스트 수정
                fnEditChannelPlayList({variables: {request: {...request}}});
            }
        }
    });
    FormikErrorSnackbar(createFormik);

    // 선택한 채널 플레이리스트
    const [selectedChannelPlayList, setSelectedChannelPlayList] = useState<ChannelPlayList | null>(null);

    // 수정
    const fnEdit = (channelPlayList: ChannelPlayList) => {
        setThumbnail(selectedChannelPlayList?.thumbnail ? [selectedChannelPlayList.thumbnail] : []);
        setSelectedChannelPlayList(channelPlayList);

        createFormik.setFieldValue('name', channelPlayList.name);
        createFormik.setFieldValue('openToThePublic', channelPlayList.openToThePublic);
    };

    useEffect(() => {
        createFormik.resetForm();
        setThumbnail([]);
        setSelectedChannelPlayList(null);

        // 채널 플레이리스트 목록 조회
        fnGetMyChannelPlayListList({variables: {request: {}}});
    }, [open]);

    ////////// 파일 업로더 관련

    // 썸네일 업로드
    const fnUploadThumbnail = (fileIds: string[]) => {
        createFormik.setFieldValue('thumbnailFileId', fileIds[0]);
    };

    // 썸네일 삭제
    const fnDeleteThumbnail = ({
                                   file,
                                   fileId,
                                   allFiles,
                                   allFileIds
                               }: { file?: File, fileId?: string, allFiles?: File[], allFileIds?: string[] }) => {
        createFormik.setFieldValue('thumbnailFileId', '');
    };

    // 썸네일 파일업로더 옵션
    const thumbnailOption: DropzoneComponentOptions = {
        accept: AcceptType.IMAGE,
        customStyle: 'h-56',
        onUpload: fnUploadThumbnail,
        onDelete: fnDeleteThumbnail,
        attachments: thumbnail,
        isPublic: true,
    };

    return (
        <div className={styles.lytNewplaylist}>
            <div className={styles.addArea}>
                <div className={styles.newList}>
                    <h4>
                        <div className={styles.tit}>{t('새 플레이리스트')}</div>
                    </h4>
                    <div className="flex flex-wrap">
                        <Box className="w-1/2">
                            <DropzoneComponent {...thumbnailOption} />
                        </Box>
                        <Box className="w-1/2 text-left pt-[109px]">
                            <ul className={styles.radioGroup}>
                                <li className={styles.radioItem}>
                                    <input
                                        type="radio"
                                        id="radio001"
                                        name="name001"
                                        className={styles.formRadio}
                                        onChange={() => createFormik.setFieldValue('openToThePublic', true)}
                                        checked={createFormik.values.openToThePublic === true}
                                    />
                                    <label className={styles.labelRadio} htmlFor="radio001">{t('공개')}</label>
                                </li>
                                <li className={styles.radioItem}>
                                    <input
                                        type="radio"
                                        id="radio002"
                                        name="name001"
                                        className={styles.formRadio}
                                        onChange={() => createFormik.setFieldValue('openToThePublic', false)}
                                        checked={createFormik.values.openToThePublic === false}
                                    />
                                    <label className={styles.labelRadio} htmlFor="radio002">{t('비공개')}</label>
                                </li>
                            </ul>
                            <div className={styles.inputPart}>
                                <input
                                    type="text"
                                    title="새 플레이리스트 제목 입력"
                                    placeholder={t('새 플레이리스트 입력')}
                                    name="name"
                                    value={createFormik.values.name}
                                    onChange={createFormik.handleChange}
                                />
                            </div>
                            <div className={styles.inputPart}>
                                {selectedChannelPlayList === null &&
                                    <button className={clsx(styles.btn, styles.sbmit)} type="button" onClick={createFormik.submitForm}>{t('추가')}</button>
                                }
                                {selectedChannelPlayList !== null &&
                                    <Grid container spacing={3} alignItems="flex-start">
                                        <Grid item xs={6}>
                                            <button className={clsx(styles.btn, styles.sbmit)} type="button" onClick={createFormik.submitForm}>{t('수정')}</button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <button className={clsx(styles.btn, styles.cancel)} type="button" onClick={() => {
                                                createFormik.resetForm();
                                                setThumbnail([]);
                                                setSelectedChannelPlayList(null);
                                            }}>{t('취소')}</button>
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        </Box>
                    </div>
                </div>
                <div className={styles.existingList}>
                    {channelPlayLists?.length > 0 &&
                        <ul>
                            {channelPlayLists?.map((channelPlayList: ChannelPlayList) => (
                                <li className={styles.list} key={channelPlayList.id}>
                                    <div className={styles.listName}>[{channelPlayList.openToThePublic === true ? '공개' : '비공개'}] {channelPlayList.name} [{channelPlayList.channelContents?.length?.toLocaleString() || 0}]</div>
                                    {channelPlayList.owner?.id === user?.id &&
                                        <button className={clsx(styles.btn, styles.delete)} type="button" onClick={async () => {
                                            await confirm({
                                                variant: 'danger',
                                                catchOnCancel: false,
                                                title: '삭제 확인',
                                                description: `플레이리스트를 삭제하시겠습니까?`,
                                            });

                                            // 채널 플레이리스트 삭제
                                            fnDeleteChannelPlayList({variables: {request: {id: channelPlayList.id}}});
                                        }}>{t('삭제')}</button>
                                    }
                                    {/* TODO: hlee - 수정 */}
                                    {/*
                                    {channelPlayList.owner?.id === user?.id &&
                                        <button className={clsx(styles.btn, styles.modify)} type="button" onClick={() => fnEdit(channelPlayList)}>{t('수정')}</button>
                                    }
                                    */}
                                    {channelPlayList.channelContents?.filter((channelContent: ChannelContent) => channelContent.id === channelContentId && channelContent.myPlayList)?.length === 0 ?
                                        <button className={clsx(styles.btn, styles.select)} type="button" onClick={() => {
                                            // 채널 플레이리스트 콘텐츠 추가
                                            fnAddChannelPlayListContent({variables: {request: {channelPlayListId: channelPlayList.id, channelContentId: channelContentId}}});
                                        }}>{t('선택')}</button>
                                        :
                                        <button className={clsx(styles.btn, styles.selected)} type="button" onClick={() => {
                                            // 채널 플레이리스트 콘텐츠 삭제
                                            fnDeleteChannelPlayListContent({variables: {request: {channelPlayListId: channelPlayList.id, channelContentId: channelContentId}}});
                                        }}>{t('해제')}</button>
                                    }
                                </li>
                            ))}
                        </ul>
                    }
                    {(!channelPlayLists || channelPlayLists?.length === 0) &&
                        <NoData loading={channelPlayListsLoading} className='w-full h-full min-h-full flex items-center'/>
                    }
                </div>
                <div className={styles.comBtnArea}>
                    <div className={styles.btnArea}>
                        <div className={clsx(styles.alignArea, styles.center)}>
                            <button className={clsx(styles.btn, styles.grey, styles.lg)} onClick={() => !!onClose && onClose()}>{t('닫기')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

